import '../../stylesheets/falcon/top'
import './common'

export default class Home {
  constructor() {
    new Effect();

    const $topMv = $('.js-top_mv');
    const $pic01 = $('.js-pic--01');
    const $pic02 = $('.js-pic--02');
    const $pic03 = $('.js-pic--03');
    const $slogan = $('.js-top_mv__slogan');

    setTimeout(() => {
      $topMv.addClass('active');
    }, 500);
    setTimeout(() => {
      $pic03.addClass('active');
    }, 1000);
    setTimeout(() => {
      $pic01.addClass('active');
    }, 1100);
    setTimeout(() => {
      $pic02.addClass('active');
    }, 1200);
    setTimeout(() => {
      $slogan.addClass('active');
    }, 2000);
  }
}

$(window).on('load', function () {
  // if (typeof NodeList.prototype.forEach !== "function") {
  //   NodeList.prototype.forEach = Array.prototype.forEach;
  // }

  new Home();
});

class Effect {
  constructor() {
    this.elms = $('.js-effect');
    this.arrRenewS = [];

    $(window).on('load', () => {
      this.handling();
    });

    $(window).on('scroll', () => {
      this.handling();
    });
  }

  handling() {
    this.arrRenewS = [];
    this.elms.each((index, el) => {
      if ($(this).hasClass('fvb')) {
        if (this.isFullyVisible(el)) {
          if (!$(this).hasClass('active')) {
            this.arrRenewS.push(el);
          }
        }
      } else {
        if (this.isPartiallyVisible(el)) {
          if (!$(this).hasClass('active')) {
            this.arrRenewS.push(el);
          }
        }
      }
    });
    for (var i = 0; i < this.arrRenewS.length; i++) {
      this.arrRenewS[i].classList.add('active');
      // this.arrRenewS[i].style.transitionDelay = (i * 60) / 300 + 's';
    }
  }

  isPartiallyVisible(el) {
    const elementBoundary = el.getBoundingClientRect();
    const top = elementBoundary.top;
    const bottom = elementBoundary.bottom;
    const height = elementBoundary.height;
    return top + height >= 0 && height + window.innerHeight >= bottom;
  }

  isFullyVisible(el) {
    const elementBoundary = el.getBoundingClientRect();
    const top = elementBoundary.top;
    const bottom = elementBoundary.bottom;
    return top >= 0 && bottom <= window.innerHeight;
  }
}
