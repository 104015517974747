import 'picturefill';
import 'smoothscroll-for-websites';
import * as objectFitImages from 'object-fit-images';

export default class Main {
  constructor() {
    objectFitImages();
    HeaderFix();
    Menu();
    Anchor();
  }
}

// Anchor
const Anchor = (function () {
  function Anchor() {
    const $eles = $('.js-anchor');
    const $header = $('#js-header').height();

    $eles.on('click', function(e) {
      const next = this.getAttribute('href');
      //const target = next.replace(/#/i, '.');
      if ($(next).length) {
        e.preventDefault();
        $('html, body').animate({
          scrollTop: $(next).offset().top - $header
        }, 'slow');
      }
    });

    const start = function() {
      window.scrollTo(0, 0);
      const next = window.location.hash;
      if (next) {
        $('html, body').animate({
          scrollTop: $(next).offset().top - $header
        }, 'slow');
      }
    }

    $(window).on('load', () => {
      start();
    });
  }
  return Anchor;
})();

// HeaderFix
const HeaderFix = (function () {
  function HeaderFix() {
    const $header = $('#js-header');

    const handleX = () => {
      const scrollX = $(document).scrollLeft();
      $header.css('left', `${-scrollX}px`);
    };

    const handleY = () => {
      const scrollY = $(window).scrollTop();
      if (scrollY > 0) {
        $header.addClass('header-fixed');
      } else {
        $header.removeClass('header-fixed');
      }
    };

    $(window).on('load', () => {
      handleY();
    });

    $(window).on('scroll', () => {
      handleX();
      handleY();
    });
  }
  return HeaderFix;
})();

// Menu
const Menu = (function () {
  function Menu() {
    const $trigger = $('#js-hamburger');
    const $wrap = $('#js-header__menu');

    const handle = () => {
      if (!$trigger.hasClass('active')) {
        open();
      } else {
        close();
      }
    };

    const open = () => {
      $trigger.addClass('active');
      $wrap.addClass('active');
      $('body').css('overflow', 'hidden');
    };

    const close = () => {
      $trigger.removeClass('active');
      $wrap.removeClass('active');
      $('body').css('overflow', '');
    };

    $trigger.on('click', () => {
      handle();
    });
  }
  return Menu;
})();

$(window).ready(function () {
  new Main();
});
